<template>
	<div>
		<div class="PrivacyPolicy_first_container wrapper_container bg_center">
			<div class="main_content">
				<h1 class="ke_common_title">Privacy Policy</h1>
			</div>
		</div>
		<div class="PrivacyPolicy_two_container wrapper_container">
			<div class="main_content">
				<div class="content">
					<p>The following Privacy Policy describes how we, MIB AFRICAN SOLUTIONS LIMITED</p>
					<p class="p_top_btm">
						and its Affiliates, collect, store, use, transfer, disclose and protect your Personal Information.
					</p>
					<p>
						Please read the following carefully to understand our views and practices regarding your Personal
						Information and how we will treat it. By downloading the App, you confirm that you have read, understood and
						accept the terms of this Privacy Policy set out hereunder. You also consent to the collection, use, storage,
						processing and disclosure of your Personal Information in the manner set out in this Privacy Policy. This
						Privacy Policy together with our Terms of Use and any additional terms applies to your use of the System and
						the Service.
					</p>

					<header class="p_top_btm">This Privacy Policy includes the following matters:</header>
					<ul>
						<li>1. Definition</li>
						<li>2. Personal Information which we collect</li>
						<li>3. The use of Personal Information which we collect</li>
						<li>4. Sharing of Personal Information which we collect</li>
						<li>5. Cross-border transfers of Personal Information</li>
						<li>6. Retention of Personal Information</li>
						<li>7. Access and correction of Personal Information</li>
						<li>8. Where we store your Personal Information</li>
						<li>9. Security of your Personal Information</li>
						<li>10. Changes to this Privacy Policy</li>
						<li>11. Language</li>
						<li>12. Acknowledgement and consent</li>
						<li>13. Marketing and promotional material</li>
						<li>14. Third party websites</li>
						<li>15. How to contact us</li>
					</ul>

					<header>1. DEFINITION</header>
					<p>
						Unless otherwise defined, all capitalised terms used in this Privacy Policy shall have the same meanings
						ascribed to them in our Terms of Use (as applicable).
					</p>

					<header>2. PERSONAL INFORMATION WHICH WE COLLECT</header>
					<p>
						We collect certain Personal Information about you. The Personal Information which we collect may be provided
						by you directly (for example, when you opening an Account to use the Service via App, or you otherwise
						provide Personal Information to us) or by third parties, or be collected automatically when you use the App.
						We may collect information in various forms and for various purposes (including purposes permitted under
						Applicable Law).
					</p>
					<header>Information obtained from you or from your Mobile Device directly:</header>
					<p>
						When you register and create an Account with us using the App, you have to provide to us certain Personal
						Information, including your name, BVN , date of birth, gender, education background, religion, photo,
						physical address, e-mail address, working information, marital status, emergence contact, your phone number,
						SIM card details, financial and credit information (including your Mobile Money Account details, bank
						account details, and bank verification number, where applicable) and an Account ID and/or password that you
						will use to access the App after registration.
					</p>
					<p>
						When you use the App, you have to provide to us such relevant information as may reasonably be required by
						us in order for the App to work. For example, when a payment is made through the electronic money facility
						within the App, you will provide to us information relating to the payment, such as the type of payment card
						or mobile wallet account used, the name of the issuer of that payment card or mobile wallet account, the
						name of the account holder for that payment card or mobile wallet account, the number of that payment card
						or mobile wallet account, and the amount of money paid.
					</p>
					<header>Information collected whenever you use the App or visit our Website:</header>
					<ul>
						<li>
							· Whenever you use the App or visit our Website, we may collect certain technical data concerning your
							usage such as, internet protocol (IP) address, information as web pages previously or subsequently viewed,
							duration of every visit/session, the internet device identity (ID) or media access control address, and
							also information regarding the manufacturer, model and operating system of the device that you use to
							access the App or our Website.
						</li>
						<li>
							· Whenever you use the App or visit our Website, certain information may also be collected on an automated
							basis using cookies. Cookies are small application files stored on your computer or Mobile Device. We use
							cookies to track user activity to enhance user interface and experience. Most Mobile Devices and internet
							browsers support the use of cookies; but you may adjust the settings on your mobile device or internet
							browser to reject several types of certain cookies or certain specific cookies. Your Mobile Device and/or
							browser would also enable you to delete at any time whatever cookies have previously been stored. However,
							doing so may affect the functionalities available on the App or our Website.
						</li>
						<li>
							· Whenever you use the App through your Mobile Device, we will track and collect your geo-location
							information in real-time. In some cases, you will be prompted or required to activate the Global
							Positioning System (GPS) on your Mobile Device to enable us to give you a better experience in using the
							App.
						</li>
						<li>
							· Meanwhile, your will be required to provide technical information to us while using the App, including
							the type of Mobile Device you use, a unique device identifier (for example, your mobile device’s IMEI
							number, the MAC address of your Mobile Device’s wireless network interface, or the mobile phone number
							used by your Mobile Device), mobile network information, your mobile operating system, the type of mobile
							browser you use, time zone setting. We will also collect information stored on your Mobile Device,
							including contact lists, call logs, SMS logs, Facebook and other social media details, contact lists from
							other social media accounts, photos, videos or other digital content.
						</li>
						<li>
							· We collect all your SMS data but only monitor your messages related to financial transactions. To be
							specific, we will monitor the name of the sender, the description and the amount of transaction to perform
							credit risk assessment. This enables faster and quicker loan disbursal. No personal SMS data is monitored,
							read, stored or shared.
						</li>
						<li>
							· We gather all your phonebook contacts which includes their phone contact names, phone numbers, contact
							added date to augment your credit profile, additionally, this can allow you to choose your references in
							the loan application or share our app invitation to your friends easily.
						</li>
						<li>
							· We collect your geo-location information when the App is running in the foreground. We endeavour to
							cease collection of your geo-location information when the App is in the background, but such information
							may still be collected unintentionally. You can always choose to disable the geo-location tracking
							information on your Mobile Device temporarily. However, this may affect the functionalities available on
							the App.
						</li>
					</ul>
					<header>Information collected from third parties:</header>
					<p>
						· Due to the nature of the Services which we provide, we are required to work with a number of third parties
						(including Credit Reference Bureaus,Banks, Mobile Network Providers, collection agencies ,our agents,
						vendors, suppliers, contractors, partners and any others who provide services to us, perform functions on
						our behalf, or whom we collaborate with) and we may receive information about you from them. In such cases,
						we will only collect your Personal Information for or in connection with the purposes for which such third
						parties are engaged or the purposes of our collaboration with such third parties (as the case may be),
						provided that we have taken reasonable steps to ensure that such third parties would undertake to us to
						obtain your consent for the disclosure of your Personal Information to us in line with this Privacy Policy
						and Applicable Law.
					</p>
					<header>Information about third parties you provide to us:</header>
					<p>
						· You may provide us with Personal Information relating to other third party individuals (such as Personal
						Information relating to your spouse, family members, friends and emergency contact). You will of course need
						their consent to do so–see Section 12 “Acknowledgement and Consent”, below, for further information.
					</p>

					<header>3. THE USE OF PERSONAL INFORMATION WHICH WE HAVE COLLECTED</header>
					<p>
						We may use Personal Information collected for any of the following purposes as well as for such other
						purposes as are permitted by Applicable Law:
					</p>
					<header>a. We may use your Personal Information:</header>
					<ul>
						<li>
							· to identify you and to register you as a user and to administer, manage or verify your Account and your
							credit conditions as such;
						</li>
						<li>
							· to facilitate or enable any checks as we may in our discretion consider necessary before we register you
							as a user;
						</li>
						<li>· to disburse Loan and collect payments for your use of the Service;</li>
						<li>· to build credit models and perform credit scoring;</li>
						<li>
							· to comply with Applicable Law, regulations, and rules, such as those relating to “know-your-customer”
							and anti-money laundering requirements;
						</li>
						<li>· to communicate with you and to send you information in connection with the use of the App;</li>
						<li>
							· to notify you of any updates to the App or changes to the Service (including interest rate, Transaction
							Fees, etc.) available;
						</li>
						<li>· to process and respond to enquiries and feedback received from you;</li>
						<li>
							· to maintain, develop, test, enhance and personalise the App to meet your needs and preferences as a
							user;
						</li>
						<li>
							· to contact you by telephone using auto-dialed or pre-recorded message calls or text (SMS) messages (if
							applicable) as authorized for the purposes described in this Privacy Policy and the Terms of Use;
						</li>
						<li>
							· to monitor and analyse user activities and demographic data including trends and usage of the Service
							available on the App;
						</li>
						<li>· to send you direct marketing communications and information on special offers or promotions.</li>
					</ul>
					<header>
						b. We may also use your Personal Information more generally for the following purposes (although we will in
						each such case always act reasonably and use no more Personal Information than what is required for the
						particular purpose):
					</header>
					<ul>
						<li>· to undertake associated business processes and functions;</li>
						<li>
							· to monitor usage of the App and administer, support and improve the performance efficiency, user
							experience and the functions of the App;
						</li>
						<li>
							· to provide assistance in relation to and to resolve any technical difficulties or operational problems
							with the App;
						</li>
						<li>
							· to generate statistical information and anonymous analytics data for the purpose of testing, research,
							analysis and product development;
						</li>
						<li>
							· to prevent, detect and investigate any prohibited, illegal, unauthorised or fraudulent activities;
						</li>
						<li>
							· to facilitate business asset transactions (which may extend to any mergers, acquisitions or asset sales)
							involving us and/or any of our Affiliates;
						</li>
						<li>
							· to enable us to comply with our obligations under any Applicable Law (including but not limited to
							responding to regulatory enquiries, investigations or directives) and conducting audit checks, due
							diligence and investigations.
						</li>
					</ul>

					<header>4. SHARING OF PERSONAL INFORMATION WHICH WE COLLECT</header>
					<header>
						a. We may disclose to or share with Affiliates and other parties your Personal Information for any of the
						following purposes as well as for such other purposes as are permitted by Applicable Law (although we will
						in each such case always act reasonably and disclose or share no more Personal Information than what is
						required for the particular purpose):
					</header>
					<ul>
						<li>
							· where required or authorised by Applicable Law (including but not limited to responding to regulatory
							enquiries, investigations or directives, or complying with statutory or regulatory filing and reporting
							requirements), for the purpose so specified in that Applicable Law;
						</li>
						<li>
							· where there is any form of legal proceeding between you and us, or between you and another party, in
							connection with, or relating to the Service, for the purposes of that legal proceeding;
						</li>
						<li>
							· in connection with, or during negotiations of, any merger, sale of company assets, consolidation or
							restructuring, financing or acquisition of all or a portion of our business by or into another company,
							for the purposes of such a transaction (even if the transaction is eventually not proceeded with);
						</li>
						<li>
							· where we share your Personal Information with third parties (including Credit Reference Bureaus, Banks,
							Mobile Network Providers, collection agencies，our agents, vendors, suppliers, contractors, partners and
							any others who provide services to us, perform functions on our behalf, or whom we collaborate with), for
							or in connection with the purposes for which such third parties are engaged or the purposes of our
							collaboration with such third parties (as the case may be), which may include allowing such third parties
							to introduce or offer products or services to you, or other conducting activities including marketing,
							research, analysis and product development;
						</li>
						<li>
							· where we share Personal Information with Affiliates, we will only do so for the purpose of them helping
							us to provide the App or to operate our business (including, where you have subscribed to our mailing
							list, for direct marketing purposes), or for the purpose of them conducting data processing on our behalf.
							For example, our Affiliate in another country may process and/or store your Personal Information on behalf
							of our group company in your country. All of our Affiliates are committed to processing the Personal
							Information that they receive from us in line with this Privacy Policy and Applicable Law;
						</li>
						<li>
							· where we publish statistics relating to the use of the App and the Service, in which case all
							information will be aggregated and made anonymous;
						</li>
						<li>
							· when we believe in good faith that disclosure of your Personal Information is necessary to comply with
							Applicable Law, prevent physical harm or financial loss, report suspected illegal activity, or to
							investigate violations of our Terms of Use;
						</li>
					</ul>
					<header>
						b. Where it is not necessary for the Personal Information disclosed or shared with other parties to be
						associated with you, we will use reasonable endeavours to remove the means by which the Personal Information
						can be associated with you as an individual before disclosing or sharing such information.
					</header>
					<header>
						c. Other than as provided for in this Privacy Policy, we may disclose or share your Personal Information if
						we notify you of this in advance and we have obtained your consent for the disclosure or sharing.
					</header>

					<header>5. CROSS-BORDER TRANSFERS OF PERSONAL INFORMATION</header>
					<p>
						Your Personal Information may be transferred to, stored, used and processed in a jurisdiction other than
						your home nation or otherwise in the country, state and city in which you are present while using any
						Service provided by us (“Alternate Country”), to companies MIB AFRICAN SOLUTIONS LIMITED under Our group
						which are located outside of your home nation or Alternate Country and/or where Our Tifi group’s servers
						and/or service providers and partners are located outside of your home nation or Alternate Country.
					</p>
					<p>
						In such a case, We shall ensure that such Personal Information remains subject to a level of protection
						comparable to what is required under the Kenya Laws (and, in any event, in line with our commitments in this
						Privacy Policy).
					</p>
					<p>
						You understand and hereby consent to the transfer of your Personal Information out of your home nation or
						Alternate Country as described herein.
					</p>

					<header>6. RETENTION OF PERSONAL INFORMATION</header>
					<p>
						Your Personal Information will only be held for as long as it is necessary to fulfill the purpose for which
						it was collected, or for as long as such retention is required or authorised by Applicable Law. We shall
						cease to retain Personal Information, or remove the means by which the Personal Information can be
						associated with you as an individual, as soon as it is reasonable to assume that the purpose for which that
						Personal Information was collected is no longer being served by retention of Personal Information and
						retention is no longer necessary for legal or business purposes.
					</p>

					<header>7. ACCESS AND UPDATE/CORRECTION OF PERSONAL INFORMATION</header>
					<p>
						a. You may request us for access to and/or the correction of your Personal Information in our possession and
						control, by contacting us at the details provided below. Subject to Applicable Law, we reserve the right to
						charge an administrative fee for such requests.
					</p>
					<p>
						b. We reserve the right to refuse your requests for access to, or to correct, some or all of your Personal
						Information in our possession or control, if permitted or required under any Applicable Law. This may
						include circumstances where the Personal Information may contain references to other individuals or where
						the request for access or request to correct is for reasons which we reasonably consider to be trivial,
						frivolous or vexatious.
					</p>

					<header>8. WHERE WE STORE YOUR PERSONAL INFORMATION</header>
					<p>
						a. The Personal Information that we collect from you may be stored at, transferred to, or processed by third
						party service providers. We will use all reasonable endeavours to ensure that all such third party service
						providers provide a level of protection that is comparable to our commitments under this Privacy Policy.
					</p>
					<p>
						b. Your Personal Information may also be stored or processed outside of your country by personnel who work
						for us in other countries, or by our third party service providers, suppliers, contractors or Affiliates, to
						the extent permitted under Applicable law. In such a case, we will ensure that such Personal Information
						remains subject to a level of protection comparable to what is required under the laws of your country (and,
						in any event, in line with our commitments in this Privacy Policy).
					</p>

					<header>9. SECURITY OF YOUR PERSONAL INFORMATION</header>
					<p>
						Confidentiality of your Personal Information is of utmost importance to us. We will use all reasonable
						efforts to protect and secure your Personal Information against access, collection, use or disclosure by
						unauthorised persons and against unlawful processing, accidental loss, destruction and damage or similar
						risks. Unfortunately, the transmission of information via the Internet is not completely secure. Although we
						will do our best to protect your Personal Information, you acknowledge that we cannot guarantee the
						integrity and accuracy of any Personal Information which you transmit over the Internet, nor guarantee that
						such Personal Information would not be intercepted, accessed, disclosed, altered or destroyed by
						unauthorised third parties&lt;, due to factors beyond our control. You are responsible for keeping your
						Account details confidential and you must not share your password with anyone and you must always maintain
						the security of the Mobile Device that you use.
					</p>

					<header>10. CHANGES TO THIS PRIVACY POLICY</header>
					<p>
						We may review and amend this Privacy Policy at our sole discretion from time to time, to ensure that it is
						consistent with our future developments, and/or changes in legal or regulatory requirements. If we decide to
						amend this Privacy Policy, we will notify you of any such amendments by means of a general notice published
						on the App and/or Website, or otherwise to your e-mail address set out in your Account. You agree that it is
						your responsibility to review this Privacy Policy regularly for the latest information on our data
						processing and data protection practices, and that your continued use of the App or Website, communications
						with us, or access to and use of the Service following any amendments to this Privacy Policy will constitute
						your acceptance to the amendments.
					</p>

					<header>11. LANGUAGE</header>
					<p>
						If there is any inconsistency between the English version of this Privacy Policy and other language
						versions, the English version shall prevail.
					</p>

					<header>12. ACKNOWLEDGEMENT AND CONSENT</header>
					<p>
						a. By accepting the Privacy Policy, you acknowledge that you have read and understood this Privacy Policy
						and you accept all of its terms. In particular, you agree and consent to us collecting, using, disclosing,
						storing, transferring, or otherwise processing your Personal Information in accordance with this Privacy
						Policy.
					</p>
					<p>
						b. In circumstances where you provide us with Personal Information relating to other individuals (such as
						Personal Information relating to your spouse, family members, friends or emergency contact), you represent
						and warrant that you have obtained such individual’s consent for, and hereby consent on behalf of such
						individual to, the collection, use, disclosure and processing of his/her Personal Information by us.
					</p>
					<p>
						c. You also consent and expressly authorise use to contact you and your emergency contact who has expressly
						agreed upon it, to verify your information or when we are unable to contact you via other methods or when we
						have not received your payment in connection with Loan.
					</p>
					<p>
						d. You may withdraw your consent to any or all collection, use or disclosure of your Personal Information at
						any time by giving us reasonable notice in writing using the contact details stated below. You may also
						withdraw your consent for us to send you certain communications and information via any “opt-out” or
						“unsubscribe” facility contained in our messages to you. Depending on the circumstances and the nature of
						the consent which you are withdrawing, you must understand and acknowledge that after such withdrawal of
						consent, you may no longer be able to use the App or certain of the Service. A withdrawal of consent by you
						may result in the termination of your Account or of your contractual relationship with us, with all accrued
						rights and obligations remaining fully reserved. Upon receipt of your notice to withdraw consent for any
						collection, use or disclosure of your Personal Information, we will inform you of the likely consequences of
						such withdrawal so that you can decide if indeed you wish to withdraw consent.
					</p>

					<header>13. MARKETING AND PROMOTIONAL MATERIAL</header>
					<p>
						a. We may send you marketing and promotional communications via post, telephone call, short message service
						(SMS), e-mail, online messaging, or push notifications via the App to notify you about special privileges,
						promotions, or events offered or organised by us, our partners, sponsors, or advertisers, or to provide
						updates on our App and/or the products and services offered therein.
					</p>
					<p>
						b. You may opt out from receiving such marketing communications at any time by clicking on any “unsubscribe”
						facility embedded in the relevant message, or otherwise contacting us using the contact details stated
						below. Please note that if you opt out, we may still send you non-promotional messages, such as Loan
						disbursement or repayment receipts, or information about your Account or the App.
					</p>

					<p>14. THIRD PARTY WEBSITES</p>
					<p>
						a. The App and Website may contain links to websites that are operated by third parties. We do not control
						nor accept liability or responsibility for these websites and for the collection, use, maintenance, sharing,
						or disclosure of data and information by such third parties. Please consult the terms and conditions and
						privacy policies of those third party websites to find out how they collect and use your Personal
						Information.
					</p>
					<p>
						b. Advertisements contained on our App or Website operate as links to the advertiser’s website and as such
						any information they collect by virtue of your clicking on that link will be collected and used by the
						relevant advertiser in accordance with the privacy policy of that advertiser.
					</p>

					<p>15. LIMITATION OF LIABILITY</p>
					<p>
						We shall not be liable for any indirect, incidental, consequential, special, exemplary or punitive damages
						arising out of:
					</p>
					<p>a. Your use of or reliance on the App or your inability to access or use the App;</p>
					<p>
						b. Any transaction or relationship between you and any other third party, even if we have been advised of
						the possibility of such damages.
					</p>
					<p>
						We shall not be liable for delay or failure in performance resulting from causes beyond our reasonable
						control.
					</p>

					<header>16. HOW TO CONTACT US</header>
					<p>
						If you have any questions regarding this Privacy Policy or you would like to obtain access and/or make
						corrections to your Personal Information, please contact our Data Protection Officer at help@tifi.co.ke
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {}
	},
	created() {}
}
</script>

<style scoped lang="scss">
@import '@/assets/css/global.scss';
.PrivacyPolicy_first_container {
	position: relative;
	height: 990px;
	background-image: url('~@/assets/ke-img/PrivacyPolicy/first-bg.webp');
	.main_content {
		position: absolute;
		bottom: 120px;
		left: 50%;
		transform: translateX(-50%);
		h1 {
			text-align: center;
			font-size: 80px;
			color: #fff;
			text-shadow: 0px 4px rgba(0, 0, 0, 0.5);
		}
	}
}
.PrivacyPolicy_two_container {
	.main_content {
		padding: 100px 0;
		.content {
			// padding: 0 50px;
			font-family: Montserrat;
			p {
				line-height: 50px;
				font-family: Montserrat;
				@extend .ke_common_msg;
				color: #555770;
				padding: 0;
				margin: 30px 0;
			}
			.p_top_btm {
				margin: 50px 0;
			}
			header {
				line-height: 50px;
				font-family: Montserrat;
				font-weight: bold;
				font-size: 30px;
				word-break: break-word;
				color: #555770;
				margin: 50px 0;
			}
			ul {
				li {
					line-height: 50px;
					margin: 30px 0;
					@extend .ke_common_msg;
					color: #555770;
				}
			}
		}
	}
}
</style>
